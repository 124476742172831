import React from 'react';

import Layout from '@src/layouts';

import {
  MobileHeaderLayoutA,
  DesktopHeaderLayoutB,
  SectionUnderHeaderWithUnderline,
  BlocksInRowsWithOneActive,
  ContactButtonImageSection,
  TechStackWithCategories,
  SliderWithClickableBlocks,
  BlocksRedirectingToResources,
  NewClientSingleTestimonial,
  ColoredBlocksWithRedirects,
  OpacityScrolledSlider,
} from '@commons/index';

import SEO from '@commons/SEO';

import {
  SEOProps,
  headerProps,
  sectionUnderHeaderProps,
  blocksInRowsWithOneActiveProps,
  contactButtonImageSectionProps,
  opacityScrolledSliderProps,
  companyInNumbersProps,
  testimonialProps,
  sliderWithClickableBlocksProps,
  techStackWithCategoriesProps,
  blocksRedirectingToResourcesProps,
  coloredBlocksWithRedirectsProps,
} from '@pages-impl/environment/payload';
import { CompanyInNumbers } from '@pages-impl/home';

export default function Environment(props) {
  return (
    <Layout
      displayTopBanner={false}
      contactFormType="services"
      contactFormTitle="Transform your environment with us"
      {...props}
    >
      <SEO {...SEOProps} />
      <MobileHeaderLayoutA {...headerProps} />
      <DesktopHeaderLayoutB {...headerProps} />
      <SectionUnderHeaderWithUnderline {...sectionUnderHeaderProps} />
      <BlocksInRowsWithOneActive {...blocksInRowsWithOneActiveProps} />
      <ContactButtonImageSection {...contactButtonImageSectionProps} />
      <OpacityScrolledSlider {...opacityScrolledSliderProps} />
      <CompanyInNumbers {...companyInNumbersProps} />
      <NewClientSingleTestimonial {...testimonialProps} />
      <SliderWithClickableBlocks {...sliderWithClickableBlocksProps} />
      <TechStackWithCategories {...techStackWithCategoriesProps} />
      <BlocksRedirectingToResources {...blocksRedirectingToResourcesProps} />
      <ColoredBlocksWithRedirects {...coloredBlocksWithRedirectsProps} />
    </Layout>
  );
}
