import React from 'react';
import { paths } from '@src/constants/pathsEnum';
import featuredImage from './images/featured-image.jpg';
import Mesh7 from '@images/logos-partners/Mesh7-logo.svg';
import bannerImage from '@commons/contact-button-image-section/images/image4.png';
import {
  grafana,
  prometheus,
  openTelemetry,
  elkStack,
  logstash,
  kibana,
  kubernetes,
  envoy,
  googleCloud,
  aws,
  ansible,
  terraform,
  pytest,
  cypress,
  docker,
  azure,
} from '@images/logos-techstack';

import * as styles from './payload.module.scss';

export const SEOProps = {
  title: 'Network Environment Services Company - CodiLime',
  description:
    'A modern environment is key for a safe, resilient network. Our services can help you transform your environment and make your network future-proof.',
  featuredImage: `https://codilime.com${featuredImage}`,
};

export const headerProps = {
  title: <>Environment</>,
  titleHasBorder: true,
  classNames: {
    header: styles.header,
    border: styles.headerBorder,
  },
  titleBorderWidth: styles.headerMobileBorder,
};

export const sectionUnderHeaderProps = {
  textPartOne:
    'Your environment consists of the software, hardware, and equipment you use to develop your product or run your business critical applications. Whether you work with on-perm solutions, data centers, or private and public clouds, we’re here to help you. ',
  textPartTwo: (
    <span>
      We can <b>modernize, automate and transform your environment</b> by moving it to the cloud, making it intelligent,
      resilient, and self-monitoring. See what we can do to keep you ahead of the curve.
    </span>
  ),
  contactButtonLabel: 'Transform your environment',
  classNames: {
    innerWrapper: styles.underHeaderInnerWrapper,
    contentWrapper: styles.underHeaderContentWrapper,
    allText: styles.underHeaderAllText,
    text: styles.underHeaderText,
    button: styles.underHeaderButton,
    sliderInnerWrapper: styles.underHeaderSliderInnerWrapper,
  },
};

export const blocksInRowsWithOneActiveProps = {
  title: 'Our areas of expertise',
  subtitle:
    'Every environment is unique, with its own ecosystem of software and hardware. We understand their complexity and variety, which is why we perform different kinds of services to help you build, optimize, and manage your network environment.',
  blocks: [
    {
      title: 'Infrastructure',
      description: (
        <>
          Our infrastructure environment services include <b>infrastructure design, implementation, and management</b>,{' '}
          as well as security and compliance solutions to ensure your infrastructure is protected from potential
          threats. When designing and building network infrastructure, we integrate technologies that are best fit for
          purpose, including SDNs and cloud deployments where advisable. We leverage automation and IaaC solutions to
          ensure efficiency.
        </>
      ),
    },
    {
      title: 'Cloud',
      description: (
        <>
          Our cloud environment services include cloud migration, cloud management, cloud native solutions development,
          and cloud security solutions - whether on-premise or public. We help you{' '}
          <b>optimize your cloud infrastructure, reduce costs, and improve performance</b> by extending automation,
          building integrations, and assessment and architectural consulting. We also take care of security and
          monitoring.
        </>
      ),
    },
    {
      title: 'Monitoring and Observability',
      description: (
        <>
          Monitoring and alerting are key for a safe and reliable network and application environment. We can design{' '}
          <b>custom solutions for anomaly detection</b> and root cause analysis or integrate existing monitoring systems
          into a single tool. We also leverage data science using historical data and device topology to develop anomaly
          prediction models. Regardless of workload and device type, our solutions ensure 100% monitoring coverage.
        </>
      ),
      buttonLabel: (
        <>
          <u>Deep dive into our end-to-end monitoring services.</u>
        </>
      ),
      buttonHref: paths.SERVICES.E2E_MONITORING_AND_OBSERVABILITY,
      colourfulButton: true,
    },
  ],
  classNames: {
    section: styles.blocksInRowsSection,
    innerWrapper: styles.blocksInRowsInnerWrapper,
    singleBlockContainer: styles.blocksInRowsSingleBlockContainer,
    blockDescription: styles.blocksInRowsBlockDescription,
    titlesContainer: styles.blocksInRowsTitlesContainer,
  },
};

export const contactButtonImageSectionProps = {
  sectionProps: {
    classNames: {
      innerWrapper: styles.buttonImageSectionInnerWrapper,
    },
  },
  title: <>Make your environment future-proof</>,
  isH2: true,
  imageAlt: 'Make your environment future-proof',
  image: bannerImage,
  buttonTitle: 'Contact us',
  classNames: {
    customContent: styles.buttonImageSectionContent,
    imageContainer: styles.buttonImageSectionImageContainer,
    customButton: styles.buttonImageSectionButton,
  },
};

export const opacityScrolledSliderProps = {
  title: 'Why should you modernize your environment?',
  blocksGap: 48,
  topOpacity: 0.3,
  showOneBlock: true,
  textBlocks: [
    <>
      <span>Better network performance</span>, such as faster data transfer speeds, reduced latency, and better
      reliability
    </>,
    <>
      Reduced risk of cyber-attacks and data breaches with <span>increased anomaly detection</span>
    </>,
    <>
      Future-proof environment via <span>greater scalability</span>
    </>,
    <>
      <span>Advanced management tools</span> to reduce downtime and improve overall network and application performance
    </>,
    <>
      Energy efficiency and <span>cost saving</span>
    </>,
  ],
  classNames: {
    innerWrapper: styles.opacitySliderInnerWrapper,
  },
};

export const companyInNumbersProps = {
  id: 'about-codilime',
  title: 'Meet the CodiLime company',
  imgSrc: '',
  classNames: {
    section: styles.companyInNumbersSection,
    titlesContainer: styles.companyInNumbersTitlesContainer,
  },
};

export const testimonialProps = {
  testimonial: {
    quote:
      'Thanks to its expertise in networking technologies and Kubernetes, CodiLime has become our reliable ' +
      'technical partner helping us deliver the first-in-class product for monitoring security in microservices. ' +
      'CodiLime’s experienced project managers and engineers played an important role in releasing the final product ' +
      'and thus keeping promises given to clients and investors.',
    author: 'Amit Jain - Co-Founder & CTO',
    logo: Mesh7,
    logoAlt: 'Mesh7',
  },
  classNames: {
    innerWrapper: styles.testimonialsInnerWrapper,
  },
};

export const sliderWithClickableBlocksProps = {
  sectionProps: {
    title: 'How we work',
    id: 'our-approach',
  },
  imgAlt: 'Forms of partnership with us',
  classNames: {
    section: styles.sliderWithClickableBlocksSection,
    innerWrapper: styles.sliderWithClickableBlocksInnerWrapper,
  },
};

export const techStackWithCategoriesProps = {
  sectionProps: {
    title: 'Technology stack',
    subtitle:
      'Every environment is different, and each one requires a different tech stack. Whether your infrastructure is based on SDN, private or public cloud, or you use a lot of hardware, we’ve got you covered. We have the skills and abilities that allow us to work with cloud solutions as well as low-level engineering.',
    classNames: {
      titlesContainer: styles.techStackTitlesContainer,
    },
  },
  classNames: {
    innerWrapper: styles.techStackInnerWrapper,
    singleCategory: styles.techStackSingleCategory,
    subtitleStyles: styles.techStackSubtitle,
    singleColumn: styles.techStackSingleColumn,
    iconsForCategory: styles.techStackIconsForCategory,
  },
  blocksForFirstColumn: [
    {
      categoryTitle: 'Open source monitoring',
      technologiesData: [grafana, prometheus, openTelemetry, elkStack, logstash, kibana],
    },
    {
      categoryTitle: 'Cloud native services',
      technologiesData: [kubernetes, docker, envoy],
    },
    {
      categoryTitle: 'Public clouds',
      technologiesData: [googleCloud, aws, azure],
    },
    {
      categoryTitle: 'Automation',
      technologiesData: [ansible, terraform, cypress, pytest],
    },
  ],
};

export const blocksRedirectingToResourcesProps = {
  sectionProps: {
    title: 'Our publications',
    subtitle: (
      <>
        Environment is too broad of a topic to cover on just one page. Our engineers, architects, and network experts
        share their knowledge and experiences on our blog. Head to the articles to find pieces about cloud,
        infrastructure, and monitoring.
      </>
    ),
    classNames: {
      titleStyles: styles.blocksRedirectingToResourcesTitle,
      titlesContainer: styles.blocksRedirectingToResourcesTitlesContainer,
      subtitleStyles: styles.blocksRedirectingToResourcesSubtitleStyles,
    },
  },
  blocks: [
    {
      text: 'Cloud app security: important aspects you need to know',
      link: '/blog/cloud-application-security/',
    },
    {
      text: 'Microservices vs. monolith: choosing the best for your project',
      link: '/blog/microservices-vs-monolith-architecture/',
    },
    {
      text: 'Cloud native architecture - everything you need to know',
      link: '/blog/cloud-native-architecture/',
    },
    {
      text: 'Continuous monitoring and observability in CI/CD',
      link: '/blog/continuous-monitoring-and-observability-in-devops/',
    },
  ],
};

export const coloredBlocksWithRedirectsProps = {
  title: 'See more of our services',
  subtitle:
    'Networks are much more than environment infrastructure services. We support our clients with our skills in many other fields. See how we use our network expertise in different cases.',
  blocks: [
    {
      text: (
        <>
          <span>Network professional</span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.NETWORK_PROFESSIONAL_SERVICES,
    },
    {
      text: (
        <>
          <span>R&D services</span>
        </>
      ),
      link: paths.SERVICES.R_D,
    },
    {
      text: (
        <>
          <span>Network solution</span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.NETWORK_SOLUTION_SERVICES,
    },
    {
      text: (
        <>
          <span>Low-level engineering</span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.LOW_LEVEL,
    },
  ],
  classNames: {
    innerWrapper: styles.coloredBlocksInnerWrapper,
    rightPart: styles.coloredBlocksRightPart,
    title: styles.coloredBlocksTitle,
  },
};
