// extracted by mini-css-extract-plugin
export var blocksInRowsBlockDescription = "payload-module--blocks-in-rows-block-description--c7830";
export var blocksInRowsInnerWrapper = "payload-module--blocks-in-rows-inner-wrapper--ef8e1";
export var blocksInRowsSection = "payload-module--blocks-in-rows-section--e7e07";
export var blocksInRowsSingleBlockContainer = "payload-module--blocks-in-rows-single-block-container--06966";
export var blocksInRowsTitlesContainer = "payload-module--blocks-in-rows-titles-container--d326e";
export var blocksRedirectingToResourcesSubtitleStyles = "payload-module--blocks-redirecting-to-resources-subtitle-styles--4c03c";
export var blocksRedirectingToResourcesTitle = "payload-module--blocks-redirecting-to-resources-title--e9376";
export var blocksRedirectingToResourcesTitlesContainer = "payload-module--blocks-redirecting-to-resources-titles-container--11bbb";
export var buttonImageSectionButton = "payload-module--button-image-section-button--0c980";
export var buttonImageSectionContent = "payload-module--button-image-section-content--ae183";
export var buttonImageSectionImageContainer = "payload-module--button-image-section-image-container--457ae";
export var buttonImageSectionInnerWrapper = "payload-module--button-image-section-inner-wrapper--3f93d";
export var coloredBlocksInnerWrapper = "payload-module--colored-blocks-inner-wrapper--c6d98";
export var coloredBlocksRightPart = "payload-module--colored-blocks-right-part--77ebd";
export var coloredBlocksTitle = "payload-module--colored-blocks-title--320af";
export var companyInNumbersSection = "payload-module--company-in-numbers-section--a8bcc";
export var companyInNumbersTitlesContainer = "payload-module--company-in-numbers-titles-container--0b902";
export var header = "payload-module--header--7fc64";
export var headerBorder = "payload-module--header-border--aeb83";
export var headerMobileBorder = "payload-module--header-mobile-border--52489";
export var opacitySliderInnerWrapper = "payload-module--opacity-slider-inner-wrapper--b3019";
export var sliderWithClickableBlocksInnerWrapper = "payload-module--slider-with-clickable-blocks-inner-wrapper--35ca3";
export var sliderWithClickableBlocksSection = "payload-module--slider-with-clickable-blocks-section--a884d";
export var techStackIconsForCategory = "payload-module--tech-stack-icons-for-category--ac0f3";
export var techStackInnerWrapper = "payload-module--tech-stack-inner-wrapper--3b24f";
export var techStackSingleCategory = "payload-module--tech-stack-single-category--235ec";
export var techStackSingleColumn = "payload-module--tech-stack-single-column--20552";
export var techStackSubtitle = "payload-module--tech-stack-subtitle--a3211";
export var techStackTitlesContainer = "payload-module--tech-stack-titles-container--a576c";
export var testimonialsInnerWrapper = "payload-module--testimonials-inner-wrapper--11db5";
export var underHeaderAllText = "payload-module--under-header-all-text--5bd1b";
export var underHeaderButton = "payload-module--under-header-button--8ca10";
export var underHeaderContentWrapper = "payload-module--under-header-content-wrapper--a1950";
export var underHeaderInnerWrapper = "payload-module--under-header-inner-wrapper--60407";
export var underHeaderSliderInnerWrapper = "payload-module--under-header-slider-inner-wrapper--c671d";
export var underHeaderText = "payload-module--under-header-text--33ec0";